<template>
  <v-card class="elevation-0">
    <v-row v-for="n in length" :key="n">
      <v-col cols="4" md="4">
        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" md="2">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" md="2">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" md="2">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="2" md="2">
        <v-skeleton-loader class="mt-4" type="button"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "pet-loader",
  props: {
    length: { type: Number, default: 5 },
  },
};
</script>
