<template>
  <v-dialog :value="value" @input="changeHandler" width="350" persistent>
    <v-card>
      <v-card-title>
        <v-icon
          color="dark"
          class="light-grey close-btn"
          v-ripple
          @click="closeModel"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-3">
        <div class="header">
          <div>
            <h1>Add new animal</h1>
            <p class="sm">{{ subtitle }}</p>
          </div>
          <p class="sm">Step {{ step }} of 4</p>
        </div>

        <v-window v-model="step">
          <v-window-item :value="1">
            <GeneralInfo />
          </v-window-item>
          <v-window-item :value="2">
            <OtherInfo />
          </v-window-item>
          <v-window-item :value="3">
            <FreeText />
          </v-window-item>
          <v-window-item :value="4">
            <Location />
          </v-window-item>
        </v-window>

        <div class="card-footer">
          <Button @click="prevStep" color="grey-light" class="mr-5">{{
            step == 1 ? "Cancel" : "Back"
          }}</Button>
          <Button @click="nextStep" :loading="loading.createPet">
            {{ step !== 4 ? "Next" : "Confirm" }}
          </Button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import GeneralInfo from "./pet-form/general-info.vue";
import OtherInfo from "./pet-form/other-info.vue";
import FreeText from "./pet-form/free-text.vue";
import Location from "./pet-form/location.vue";

import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");

import { postcodeValidator } from "postcode-validator";
import lookUp from "country-code-lookup";

export default {
  name: "create-edit-pet",
  props: {
    value: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false }
  },
  components: { GeneralInfo, OtherInfo, FreeText, Location },
  data() {
    return {
      step: 1
    };
  },
  computed: {
    ...petModule.mapState(["loading", "createPet"]),
    subtitle() {
      const { step } = this;
      if (step === 1) return "General Info";
      else if (step === 2) return "Other Info";
      else if (step === 3) return "Free Text";
      else return "Add Location";
    }
  },

  methods: {
    ...petModule.mapMutations(["RESET_CREATE_PET_OBJ"]),
    ...petModule.mapActions([
      "FETCH_PET_CATEGORIES",
      "FETCH_SUB_CATEGORIES",
      "CREATE_PET",
      "UPDATE_PET"
    ]),

    changeHandler(val) {
      this.$emit("input", val);
    },

    prevStep() {
      if (this.step === 1) this.$emit("input", false);
      else this.step--;
    },

    nextStep() {
      const result = this.validateData();
      if (!result) return;

      if (this.step === 4) {
        this.createPetHandler();
      } else this.step++;
    },

    validateData() {
      const { step } = this;
      let errors = [];
      const pet = { ...this.createPet };
      if (step === 1) {
        let keys = [
          "category_id",
          "race_id",
          "name",
          "date_of_birth",
          "gender",
          "media"
        ];
        for (const key of keys) {
          if (Object.hasOwnProperty.call(pet, key)) {
            if (key === "media") {
              if (!pet[key].length) {
                errors.push(`Please Add Atleast one pic`);
              }
            } else if (!pet[key]) {
              errors.push(`${key} is missing.`);
            }
          }
        }
      } else if (step === 2) {
        const { meta_data, adoption, co_adoption } = pet;
        const { garden_size, active_hours } = meta_data;

        if (!garden_size) {
          errors.push("Please Enter the garden size");
        }

        if (!adoption && !co_adoption) {
          errors.push("Please Select the adoption type.");
        }

        if (active_hours <= 0) {
          let text = "Please Add Some Active Hours";
          if (active_hours < 0) text = "Active Hours cannot be negative";
          errors.push(text);
        }
        if (active_hours > 24)
          errors.push("Cannot be active more then 24 hours");
      } else if (step === 3) {
        const { description } = pet;
        if (!description) {
          errors.push("Please Add Some Details about your pet");
        }
      } else {
        const keys = ["city", "country", "longitude", "latitude"];

        for (const key of keys) {
          if (Object.hasOwnProperty.call(pet, key)) {
            if (!pet[key]) {
              errors.push(`${key} is missing.`);
            }
          }
        }

        // const { postal_code } = pet;
        // if (postal_code) {
        //   const isValid = this.validatePostalCode(postal_code);
        //   if (!isValid) errors.push("Invalid Postal COde");
        // }
      }

      errors.forEach(err => {
        this.$notify({
          group: "notification",
          title: "Missing",
          text: err,
          type: "error",
          duration: 5000
        });
      });

      if (errors.length) return false;
      return true;
    },

    async createPetHandler() {
      let result = false;

      if (!this.editMode) {
        result = await this.CREATE_PET();
      } else {
        result = await this.UPDATE_PET();
      }

      if (result) {
        this.closeModel();
        this.$emit("success");
      }
    },

    closeModel() {
      // this.RESET_CREATE_PET_OBJ();
      this.$emit("input", false);
      this.step = 1;
    },

    capitalizeWords(string) {
      return string
        .replace(/(?:^|\s)\S/g, function(a) {
          return a.toUpperCase();
        })
        .replace(/\s+/g, " ")
        .trim();
    },

    validatePostalCode(postal_code) {
      try {
        let country = this.createPet.country;
        if (!country) return false;

        if (country.toLowerCase() === "belgië") country = "Belgium";

        const result = lookUp.byCountry(this.capitalizeWords(country));
        if (!result || !result.internet) return false;

        return postcodeValidator(postal_code, result.internet);
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  },

  watch: {},
  created() {
    this.FETCH_PET_CATEGORIES();
    if (this.editMode) {
      const { category_id } = this.createPet;
      if (category_id) {
        this.FETCH_SUB_CATEGORIES(category_id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  .v-btn:nth-child(2) {
    flex: 1;
  }
}

p.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7f7d94;
}

.close-btn {
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}
</style>
