<template>
  <div class="picker-container">
    <input
      type="file"
      ref="mediaPicker"
      accept="image/*"
      multiple
      class="d-none"
      @change="getMedia"
    />
    <div
      class="empty-state"
      @click="$refs.mediaPicker.click()"
      v-if="!media.length"
    >
      <div>
        <v-icon color="primary" size="4rem">mdi-paw</v-icon>
        <p class="primary--text">+Add Animal Potrait</p>
        <p class="light-grey--text">upto 4 images, max size 5mb</p>
      </div>
    </div>

    <div class="images" v-else>
      <div class="images-item" v-for="m in media" :key="m.id">
        <v-img :src="m.src" class="image" cover rounded height="80px">
          <template v-slot:placeholder>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
        </v-img>
        <v-icon
          color="dark"
          class="white remove-image elevation-1"
          @click="removeImage(m.id)"
          >mdi-close</v-icon
        >
      </div>

      <div class="images-item add-more" @click="$refs.mediaPicker.click()">
        <div>
          <v-icon color="primary">mdi-plus</v-icon>
          <p class="primary--text">Add More</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pet-media-picker",
  props: { value: { type: Array, default: () => [] } },
  data() {
    return {
      media: [],
    };
  },
  methods: {
    getMedia(e) {
      const files = e.target.files;
      if (files.length) {
        for (const file of files) {
          const src = URL.createObjectURL(file);
          this.media.push({
            id: file.size + file.name,
            src,
            file,
          });
        }
        this.emitMedia();
      }
    },
    removeImage(id) {
      const index = this.media.findIndex((m) => m.id == id);
      if (index != -1) {
        this.media.splice(index, 1);
      }
      this.emitMedia();
    },
    emitMedia() {
      this.$emit("input", this.media);
    },
  },
  created() {
    this.media = this.value;
  },
};
</script>

<style lang="scss" scoped>
$min-height: 250px;
.picker-container {
  min-height: $min-height;
  border-radius: 12px;
  border: 1px dashed #bfbec9;
  margin: 1rem 0;

  .empty-state {
    height: $min-height;
    display: grid;
    place-items: center;
    text-align: center;
    cursor: pointer;
    p {
      margin: 0.7rem 0;
    }
  }

  .images {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 10px;
    align-items: center;

    &-item {
      position: relative;
      min-height: 80px;

      .image {
        border-radius: 12px;
      }

      .remove-image {
        position: absolute;
        top: -6px;
        right: -4px;
        border-radius: 100%;
        z-index: 1111;
      }

      &.add-more {
        border: 1px dashed #0dbff2;
        border-radius: 12px;
        height: 100%;
        text-align: center;
        display: grid;
        place-items: center;
        cursor: pointer;
        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
