<template>
  <div>
    <PetMediaPicker v-model="media" />

    <InputGroup v-model="name" label="Pet Name" :rules="[rules.required]" />

    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date_of_birth"
          label="Date of Birth (yy/mm/dd)"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date_of_birth"
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
        min="1950-01-01"
        @change="saveDateOfBirth"
      ></v-date-picker>
    </v-menu>

    <v-select
      v-model="gender"
      :items="genderList"
      label="Gender"
      item-text="text"
      item-value="value"
    ></v-select>

    <v-select
      :items="categories"
      label="Animal Type"
      v-model="category_id"
      item-text="name"
      item-value="id"
      :loading="loading.category"
      @input="categoryChange" 
    ></v-select>

    <v-select
      :items="subCategories"
      label="Animal Race"
      v-model="race_id"
      item-text="name"
      item-value="id"
      :loading="loading.subCategory"
    ></v-select>
  </div>
</template>

<script>
import PetMediaPicker from "@/components/image-picker/pet-media-picker";

import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");

import { required } from "@/helpers/form-validations";
import { GENDER_LIST } from "@/constants";

export default {
  name: "general-info",
  components: { PetMediaPicker },
  data() {
    return {
      activePicker: null,
      menu: false,
    };
  },
  computed: {
    ...petModule.mapState([
      "loading",
      "categories",
      "subCategories",
      "createPet",
    ]),
    rules() {
      return {
        required,
      };
    },
    genderList() {
      return GENDER_LIST;
    },
    // form values
    name: {
      get() {
        return this.createPet.name;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "name", value });
      },
    },
    date_of_birth: {
      get() {
        return this.createPet.date_of_birth;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "date_of_birth", value });
      },
    },
    gender: {
      get() {
        return this.createPet.gender;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "gender", value });
      },
    },
    category_id: {
      get() {
        return this.createPet.category_id;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "category_id", value });
      },
    },
    race_id: {
      get() {
        return this.createPet.race_id;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "race_id", value });
      },
    },
    media: {
      get() {
        return this.createPet.media;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "media", value });
      },
    },
  },
  methods: {
    ...petModule.mapMutations(["SET_CREATE_PET"]),
    ...petModule.mapActions(["FETCH_PET_CATEGORIES", "FETCH_SUB_CATEGORIES"]),
    categoryChange(val) { 
      if (val) { 
        this.race = ""; 
        this.FETCH_SUB_CATEGORIES(val); 
        this.race_id="";
      } 
    }, 
    saveDateOfBirth(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

<style lang="scss" scoped>
p.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7f7d94;
}
.subtitle-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #7f7d94;
  margin: 1rem 0 8px 0;
}
</style>
