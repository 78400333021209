<template>
  <div class="window-3">
    <p class="subtitle-1 d-flex align-items-center justify-content-between">
      <span v-bind:class="{ limitExceed: !descriptionRemaining }"
        >Anything else about animal</span
      >
      <span v-bind:class="{ limitExceed: !descriptionRemaining }"
        >{{ description.length }}/500</span
      >
    </p>

    <v-textarea
      outlined
      v-model="description"
      v-on:input="descriptionCheck"
      :maxlength="this.textareaLimit"
    ></v-textarea>

    <p class="subtitle-1 d-flex align-items-center justify-content-between">
      <span v-bind:class="{ limitExceed: !disclaimerRemaining }"
        >Some Additional Info</span
      >
      <span v-bind:class="{ limitExceed: !disclaimerRemaining }"
        >{{ disclaimer ? disclaimer.length : 0 }}/500</span
      >
    </p>

    <v-textarea
      outlined
      v-model="disclaimer"
      v-on:input="disclaimerCheck"
      :maxlength="this.textareaLimit"
    ></v-textarea>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");
export default {
  name: "free-text",
  data() {
    return {
      textareaLimit: 500,
    };
  },
  computed: {
    ...petModule.mapState(["createPet"]),
    description: {
      get() {
        return this.createPet.description;
      },
      set(value) {
        this.SET_CREATE_PET({ value, key: "description" });
      },
    },
    disclaimer: {
      get() {
        return this.createPet.disclaimer;
      },
      set(value) {
        this.SET_CREATE_PET({ value, key: "disclaimer" });
      },
    },
    descriptionRemaining: function() {
      return this.textareaLimit - this.description.length;
    },
    disclaimerRemaining: function() {
      return this.textareaLimit - this.disclaimer.length;
    },
  },
  methods: {
    ...petModule.mapMutations(["SET_CREATE_PET"]),
    descriptionCheck: function() {
      this.description = this.description.substr(0, this.textareaLimit);
    },
    disclaimerCheck: function() {
      this.disclaimer = this.disclaimer.substr(0, this.textareaLimit);
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #7f7d94;
  margin: 1rem 0 8px 0;
}

p.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7f7d94;
}
.limitExceed {
  color: rgba(255, 153, 0, 0.925);
  font-weight: bold;
}
</style>
