<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="$attrs['max-width'] || '600px'"
      v-bind="$attrs"
      persistent
    >
      <v-card>
        <slot name="header">
            <span class="text-h5"></span>
        </slot>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-spacer></v-spacer>
        <slot name="footer"></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    
    inheritAttrs: false,

    props: {
      open: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      dialog: {
        set() {
          this.$emit('close')
        },
        get() {
          return this.open 
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
.v-dialog::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.v-dialog::-webkit-scrollbar-track {
  background: orange !important;        /* color of the tracking area */
}

.v-dialog::-webkit-scrollbar-thumb {
  background-color: blue;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid orange;  /* creates padding around scroll thumb */
}
</style>