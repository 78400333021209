<template>
  <div class="window-2">
    <p class="subtitle-1">Adoption Type</p>
    <div class="adoption">
      <div
        class="adoption--item"
        :class="`${adoption ? 'active' : ''}`"
        @click="adoption ? (adoption = 0) : (adoption = 1)"
        v-ripple
      >
        <img src="@/components/svg/home.svg" alt="" />
        <p class="adoption--item-label">Adoption</p>
      </div>

      <div
        class="adoption--item"
        :class="`${co_adoption ? 'active' : ''}`"
        @click="co_adoption ? (co_adoption = 0) : (co_adoption = 1)"
        v-ripple
      >
        <img src="@/components/svg/bone-icon.svg" alt="" />
        <p class="adoption--item-label">Co Ownership</p>
      </div>
    </div>

    <p class="subtitle-1">Pet Interaction</p>
    <v-checkbox
      v-model="pet_interaction"
      hide-details
      label="Kids Friendly"
      value="kids_friendly"
    ></v-checkbox>
    <v-checkbox
      v-model="pet_interaction"
      hide-details
      label="Dog Friendly"
      value="dog_friendly"
    ></v-checkbox>
    <v-checkbox
      v-model="pet_interaction"
      hide-details
      label="Other Animal Friendly"
      value="other"
    ></v-checkbox>

    <div class="garden">
      <div class="details pt-0">
        <p class="subtitle-1">Vaccine</p>
        <v-switch v-model="vaccine" inset hide-details></v-switch>
      </div>
      <v-select
        v-model="garden_size"
        :items="gardenSizeList"
        label="Garden Size"
        item-text="text"
        item-value="value"
      ></v-select>

      <p class="subtitle-1">Amount of active time recommended</p>
      <input
        type="number"
        v-model="active_hours"
        class="garden-input"
        placeholder="Hours"
        min="1"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");

import { GARDEN_SIZES } from "@/constants";
export default {
  name: "other-info",
  data() {
    return {};
  },
  computed: {
    ...petModule.mapState(["createPet"]),
    gardenSizeList() {
      return GARDEN_SIZES;
    },
    garden_size: {
      get() {
        return this.createPet.meta_data.garden_size;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.garden_size = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    garden: {
      get() {
        return this.createPet.meta_data.garden;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.garden = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    vaccine: {
      get() {
        return this.createPet.vaccine;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "vaccine", value });
      },
    },
    pet_interaction: {
      get() {
        return this.createPet.meta_data.pet_interaction;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.pet_interaction = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    garden_length: {
      get() {
        return this.createPet.meta_data.garden_length;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.garden_length = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    garden_width: {
      get() {
        return this.createPet.meta_data.garden_width;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.garden_width = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    active_hours: {
      get() {
        return this.createPet.meta_data.active_hours;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.active_hours = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    minimal_adopt: {
      get() {
        return this.createPet.meta_data.minimal_adopt;
      },
      set(value) {
        const meta_data = { ...this.createPet.meta_data };
        meta_data.minimal_adopt = value;
        this.SET_CREATE_PET({ key: "meta_data", value: meta_data });
      },
    },
    adoption: {
      get() {
        return this.createPet.adoption;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "adoption", value });
      },
    },
    co_adoption: {
      get() {
        return this.createPet.co_adoption;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "co_adoption", value });
      },
    },
  },
  methods: {
    ...petModule.mapMutations(["SET_CREATE_PET"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.window-2 {
  padding-bottom: 2rem;

  &::v-deep .v-input--selection-controls {
    margin-top: 0;
  }
}
p.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7f7d94;
}
.subtitle-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #7f7d94;
  margin: 1rem 0 8px 0;
}

.garden {
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  &-area {
    display: flex;
    align-items: center;

    .garden-input {
      width: 110px;
      margin-top: 0;
    }
  }

  &-input {
    width: 90px;
    border: 0.5px solid #000000;
    border-radius: 4px;
    padding: 10px;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
  }
}

.adoption {
  &--item {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    padding: 0.8rem 1rem;
    border-radius: 12px;
    cursor: pointer;

    p {
      margin-bottom: 0;
      margin-left: 1rem;
    }

    &.active {
      border-color: #0dbff2;
      background: #f5f2e9;
    }
  }
}
</style>
