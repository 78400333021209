<template>
  <v-card outlined class="mb-3" style="border-radius:12px;">
    <v-card-text>
      <v-row align-content="center">
        <v-col cols="4" @click="$router.push(`pet/${pet.id}`)">
          <div class="d-flex flex-row align-center pointer text-left">
            <v-img
              v-if="pet.media.length"
              :src="pet.media[0]"
              height="56px"
              width="56px"
              max-width="56px"
              cover
              class="pet-img"
            ></v-img>
            <v-avatar color="grey" size="56" v-else>
              <span class="white--text text-h5 text--capitalize">{{
                pet.name.substr(0, 2)
              }}</span>
            </v-avatar>
            <div class="ml-3">
              <h5 class="text--lg text--light-black font--500">
                {{ pet.name }}
              </h5>
              <p
                class="text--new-grey text-subtitle-2 font--normal"
                v-if="pet.race"
              >
                {{ pet.race.name }}
              </p>
           
            </div>
               
          </div>
        </v-col>
<!-- {{pet}} -->
        <v-col cols="2" class="col-item">
          <h5 class="text--lg"> <span v-if="pet.new_adoption_request.length!=0" class="red-dot"></span>{{ pet.adoption_request }} </h5>
          <p class="text--new-grey text--xs font--normal">
            Adoption Requests
          </p>
        </v-col>

        <v-col cols="2" class="col-item">
          <h5 class="text--lg"> <span v-if="pet.new_co_adoption_request.length!=0" class="red-dot"></span>{{ pet.co_adoption_request }}</h5>
          <p class="text--new-grey text--xs font--normal">
            Co own Requests
          </p>
        </v-col>

        <v-col cols="2" class="col-item">
          <h5 class="text--lg">{{ pet.likes }}</h5>
          <p class="text--new-grey text--xs font--normal">Likes</p>
        </v-col>

        <v-col cols="2" class="col-item action">
          <Button color="#E5E7EB" @click="$emit('edit-pet', pet)">Edit</Button>
          <v-icon class="ml-3" @click="$router.push(`pet/${pet.id}`)"
            >mdi-chevron-right</v-icon
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "pet-list-card",
  props: { pet: { type: Object, required: true } },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

.pet-img {
  border-radius: 12px;
}

.col-item {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.action {
    flex-direction: row;
    align-items: center;
  }
}
.red-dot {
 display: block;
    height: 6px;
    width: 6px;
    background: #ff5f5f;
    border-radius: 100%;
    position: relative;
    right: -13px;
    top: 5px;
}
</style>
