<template>
  <div>
    <div class="image-upload-section">
      <div class="text-center">
        <v-icon size="5rem" color="primary">mdi-paw</v-icon>
        <h1 class="mt-5">No pets found</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pet-empty-state",
};
</script>

<style lang="scss" scoped>
.max-width-800 {
  max-width: 800px;
}
.primary--text {
  margin-bottom: 2px;
}
.thumbnail {
  width: 56px;
  height: 56px;
}
.v-application p {
  margin-bottom: 0;
}
.dog-with-girl-image {
  position: absolute;
  right: 28%;
  bottom: 10px;
}
.image-upload-section {
  box-sizing: border-box;
  border-radius: 17px;
  border: 1px dashed #7f7d94;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  min-height: 300px;
}
</style>
