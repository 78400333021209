import { validateEmail } from "./index";

export const validEmail = (email) => validateEmail(email) || "Email is Invalid";

export const required = (val) => !!val || "Field is required.";

export const minPasswordLength = (val) => val.length >= 8 || "Min 8 characters";

export const gt0 = (val) => Number(val) > 0 || "Amount should be greater than 0"

export const isNumber = (val) => !isNaN(val) || "Invalid value"

export const notIn0 = (val) => (val != 0 || val != null || val != '') || 'No value selected'
