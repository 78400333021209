<template>
  <div class="window-4">
    <p class="subtitle-1 d-flex align-items-center justify-content-between">
      <span>My Current Location</span>
      <v-icon
        color="primary"
        class="cursor=pointer"
        v-ripple
        @click="getCurrentLocation"
        >mdi-crosshairs-gps
      </v-icon>
    </p>

    <div class="map-input my-5">
      <gmap-autocomplete
        placeholder="Enter your address"
        @place_changed="getSearchLocation"
      >
      </gmap-autocomplete>
    </div>

    <template v-if="address">
      <div :key="reRender">
        <EditableField
          v-model="address"
          label="Street"
          placeholder="Enter Your Address"
        />
        <EditableField
          v-model="country"
          label="Country"
          placeholder="Enter Your Country"
        />
        <EditableField v-model="city" label="City" placeholder="City" />
        <EditableField
          v-model="postal_code"
          label="Postal Code"
          placeholder="Postal Code"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");
const userModule = createNamespacedHelpers("user");

import EditableField from "@/components/editable-field/editable-field";

export default {
  name: "pet-location",
  components: { EditableField },
  data() {
    return {
      reRender: false,
    };
  },
  computed: {
    ...petModule.mapState(["createPet"]),
    ...userModule.mapState(["user"]),
    city: {
      get() {
        return this.createPet.city;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "city", value });
      },
    },

    country: {
      get() {
        return this.createPet.country;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "country", value });
      },
    },
    address: {
      get() {
        return this.createPet.address;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "address", value });
      },
    },

    postal_code: {
      get() {
        return this.createPet.postal_code;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "postal_code", value });
      },
    },

    latitude: {
      get() {
        return this.createPet.latitude;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "latitude", value });
      },
    },

    longitude: {
      get() {
        return this.createPet.longitude;
      },
      set(value) {
        this.SET_CREATE_PET({ key: "longitude", value });
      },
    },
  },

  methods: {
    ...petModule.mapMutations(["SET_CREATE_PET"]),
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((res) => {
        const position = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };

        const { lat, lng } = position;
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ latLng: latlng }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.getSearchLocation(results[0]);
            } else {
              console.log("address not found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        });
      });
    },
    getSearchLocation(place) {
      this.reRender = !this.reRender;
      const { geometry, formatted_address, address_components } = place;
      this.SET_CREATE_PET({ key: "latitude", value: geometry.location.lat() });
      this.SET_CREATE_PET({ key: "longitude", value: geometry.location.lng() });
      this.SET_CREATE_PET({ key: "address", value: formatted_address });

      address_components.forEach((component) => {
        if (component.types.includes("postal_code"))
          this.SET_CREATE_PET({
            key: "postal_code",
            value: component.long_name,
          });
        if (component.types.includes("country")) {
          this.SET_CREATE_PET({ key: "country", value: component.long_name });
        }
        if (component.types.includes("locality"))
          this.SET_CREATE_PET({ key: "city", value: component.long_name });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p.sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7f7d94;
}

.subtitle-1 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #7f7d94;
  margin: 1rem 0 8px 0;
}

.pac-target-input {
  padding: 10px 5px;
  border: 1px solid #333;
  border-radius: 10px;
  width: 100%;
}

ul {
  padding: 0;
  margin: 1rem 0;
  list-style: none;
  li {
    p {
      margin-bottom: 0;
    }
    p.label {
      font-weight: bold;
    }
    .value {
      font-size: 12px;
      padding-left: 10px;
      font-weight: normal;
    }
  }
}
</style>
